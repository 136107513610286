import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import CSSModules from 'react-css-modules';
import styles from './login.css';

import { inputValidation, blurValidation, submitValidation } from '../../../../common/validation';

import { logInAction } from '../../../services/actions/userActions';
import Spinner from '../../../components/spinner/Spinner';

import Button from '../../../components/button/Button';
import Logo from "../../../components/logo/Logo"
import {setCookies} from "../../../components/cookies/Cookies"
import { transEL, transLoc, publicHost } from '../../../services/helpers/lang';
import appConfig from '../../../../configs/appConfig';

@connect(null, {
  logInAction
})
@CSSModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
export default class LogIn extends Component {
        
    state = {
      email: {
        value: '',
        dirty: false,
        invalid: false
      },
      password: {
        value: '',
        dirty: false,
        invalid: false
      },
      message: ''
    };
    
    _input = e => inputValidation(this, e.currentTarget);

    _blur = e => blurValidation(this, e.currentTarget);

    _submit = async e => {
      e.preventDefault();

      if (submitValidation(this, [ 'email', 'password' ])) return;

      let { email, password } = this.state;
      let { logInAction } = this.props;
      mixpanel.track('login');
      
      logInAction({ email: email.value, password: password.value, projectId: this._project, documentId: this._document, history: this._history })
        .catch(resp => {
          let { data: { err } } = resp.response;
          this.setState({ message: err });
        });

      this.setState({
        email: { ...email, value: '' },
        password: { ...password, value: '' }
      });
      setCookies()
    };
    
    _demo = async () => {
        let email = 'demo@contract.one', password = 'democontractone';
        let { logInAction } = this.props;
        this._project = null;
        logInAction({ email: email, password: password })
        .catch(resp => {
          let { data: { err } } = resp.response;
          this.setState({ message: err });
        });
    }

    render() {
      let { email, password, message } = this.state;
      
      const { token, projectId, documentId, history } = this.props.match.params;
      if (token ===  1) {
          this._project = projectId;
          this._document = documentId;
          this._history = history;
      }
      else if (token === 'F34y87Y73F3D') {
            this._demo();
            return <Spinner />;
      } else this._project = token;

      const isRedirectToNewProject = new URLSearchParams(window.location.search).get('redirect') === 'newProject'
      return (
        <main styleName='wrapper'>
            <form onSubmit={this._submit} noValidate>
              <div styleName='form-inner'>
                  <div styleName='logo'><Logo link={publicHost}/></div>
                  <div styleName='title'>{transLoc('Sign In')}</div>
                <label styleName='row border'>
                  <div styleName='error-message'>
                    {
                      (() => {
                        if (email.invalid === 'required') return transEL('requiredEmail');
                        else if (email.invalid === 'email') return transEL('errorEmail');
                        else if (email.invalid === 'maxLength') return transEL('maxLengthEmail');
                      })()
                    }
                  </div>
                  <input styleName='input'
                    type='email'
                    name='email'
                    value={email.value}
                    onChange={this._input}
                    onBlur={this._blur}
                    autoComplete='off'
                    placeholder='Email' />
                </label>
                <label styleName='row'>
                  <div styleName='error-message'>
                    {
                      (() => {
                        if (password.invalid === 'required') return transEL('requiredPassword');
                        else if (password.invalid === 'minLength') return transEL('minLengthPassword');
                        else if (password.invalid === 'maxLength') return transEL('maxLengthPassword');
                      })()
                    }
                  </div>
                  <input styleName='input password'
                    type='password'
                    name='password'
                    value={password.value}
                    onChange={this._input}
                    onBlur={this._blur}
                    autoComplete='off'
                    placeholder={transLoc('Password')} />
                    <div styleName='link-btn'>
                        <NavLink styleName='link-btn__a' to='/reset-password'>
                        {transLoc('Forgot password?')}</NavLink>
                    </div>
                </label>
                  <div styleName='button-row'>
                      <Button text={transLoc('Sign In')} mod='promoMainBtn' type='submit' />
                  </div>
              </div>
              {message && <div styleName='message-wrapper'>{message}</div>}
              {false &&  <div styleName='link-row'>{transLoc("Don't have an account?")} <NavLink styleName='link' 
                  to='/sign-up'>
                    <Button text={transLoc('Sign up')} mod='blue arch'/></NavLink>
                </div>}
            </form>
        </main>
      );
    }
}
